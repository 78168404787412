import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';

const headingStyles = css`
  cursor: pointer;
  text-decoration: ${({ expanded }) => (expanded ? 'underline' : 'none')};
  color: ${({ expanded }) => (expanded ? 'blue' : 'inherit')};
`;

const NewSubscriptionTableN = ({ newSubscription, handleNewSubscriptionChange, handleCreateNewSubscription, expanded, onToggle }) => {
  return (
    <div>
      <Typography
        variant="h5"
        css={headingStyles}
        expanded={expanded}
        onClick={onToggle}
      >
        New Subscription
        <span style={{ marginLeft: '5px', fontSize: '0.8em' }}>{expanded ? '▼' : '▶'}</span>
      </Typography>
      {expanded && (
        <div>
          <label>
            Package:
            <br />
            <select
              name="package_id"
              value={newSubscription.package_id}
              onChange={handleNewSubscriptionChange}
            >
              <option value="">Select Package</option>
            {/*  <option value="Genmai Content Basic">Genmai Content Basic</option>*/}
              <option value="Genmai Content Pro">Genmai Content Pro</option>
            {/*  <option value="Genmai Content Elite">Genmai Content Elite</option> 
              <option value="Genmai Web Basic">Genmai Web Basic</option>*/}
             {/* <option value="Genmai Web Pro">Genmai Web Pro</option>*/}
             {/* <option value="Genmai Web Elite">Genmai Web Elite</option> */}
            </select>
          </label>
          <TextField
            name="associated_email"
            label="Email"
            value={newSubscription.associated_email}
            onChange={handleNewSubscriptionChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          <TextField 
            name="domain_name"
            label="Domain Name"
            value={newSubscription.domain_name}
            onChange={handleNewSubscriptionChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          {newSubscription.package_id === "Genmai Content Pro" && (
          <TextField
            name="description"
            label="Business Description"
            multiline
            value={newSubscription.description}
            onChange={handleNewSubscriptionChange}
            variant="outlined"
            style={{ width: '100%', minHeight: '100px' }}
            margin="normal"
          />
          )}
        {/*  <TextField
            name="folder_link"
            label="Folder Link"
            value={newSubscription.folder_link}
            onChange={handleNewSubscriptionChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
      /> */}
      
          <Button onClick={handleCreateNewSubscription} variant="contained" color="primary">
            Create Subscription
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewSubscriptionTableN;
