import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { API_BASE_URL } from './constants';
import ReCAPTCHA  from 'react-google-recaptcha';

const initialFormData = {
  email: '',
    name: '',
    domain_name: '',
    description: '',
    package_id: ''
};
const RegisterFormN = ({ email, setEmail, handleRegister, handleLogin}) => {
  
  const captchaRef = useRef(null);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    domain_name: '',
    description: '',
    package_id: ''
  });
  const [loginformData, setloginFormData] = useState({
    email: '',
    
  });
  const [captchaToken, setCaptchaToken] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleloginChange = (e) => {
    const { name, value } = e.target;
    setloginFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to validate reCAPTCHA token
  const validateCaptcha = (token) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/validate-captcha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ captchaToken: token })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          // Captcha validation successful
          resolve(true);
        } else {
          // Captcha validation failed
          resolve(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        reject(error);
      });
    });
  };
  

const handleRegistration = async (e) => {
    e.preventDefault();
        
      const token = await captchaRef.current.executeAsync();
      console.log(token)
      
   // handleCaptchaChange(token)
    if (!token) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }
    setEmail(formData.email)
    
    // Validate reCAPTCHA
    const captchaValid = await validateCaptcha(token);
  
  if (captchaValid) {
    
    // Proceed with registration
    handleRegister(formData);
    setFormData(formData)
    
    
  } else {
    // Handle captcha validation failure
    alert('Captcha validation failed. Please try again.');
  }
};

const handleLoginAction = async (e) => {
    e.preventDefault();
      
      const token = await captchaRef.current.executeAsync();
      console.log(token)
      
   // handleCaptchaChange(token)
    if (!token) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }
    
   // console.log(captchaToken)
    setEmail(loginformData.email)
    // Validate reCAPTCHA
  const captchaValid = await validateCaptcha(token);
  
  if (captchaValid) {
    // Proceed with login
    handleLogin(loginformData);
    setloginFormData(loginformData)
    
    
  } else {
    // Handle captcha validation failure
    alert('Captcha validation failed. Please try again.');
  }
};


  
  return (
    <div>
     {/* <AppBar position="static">
        
        <Toolbar>
        <Typography variant="h6" style={{ textAlign: 'center', width: '100%' }}>GENmAI</Typography>
        </Toolbar>
      </AppBar>
  */}
      <Container maxWidth="xl" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
           {/*   <Typography variant="h4" gutterBottom align="center">
                Login
                </Typography> */}
              <form onSubmit={handleLoginAction}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={loginformData.email}
                  onChange={handleloginChange}
                  style={{ marginBottom: '20px' }}
                  required
                  sx={{ width: '100%', height: '40px' }} // Adjust the height as needed
                />
                <ReCAPTCHA
                  sitekey="6Leeeb0pAAAAAFP2KVQ-_wuo6XR25LoBladdjubE"
                  ref={captchaRef}
                  size="invisible"
                  //onChange={handleCaptchaChange} // Make sure to handle captcha change event
                />
                <Button type="submit" variant="contained" color="primary">
                  Login
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
              <Typography variant="h6" gutterBottom align="center">
                Get Started
              </Typography>
              <form onSubmit={handleRegistration}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ marginBottom: '40px' }}
                  required
                  helperText="Please use business email."
                  sx={{ width: '100%', height: '40px' }} // Adjust the height as needed
                />
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                  required
                  sx={{ width: '100%', height: '40px' }} // Adjust the height as needed
                />
                
                <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }} required>
                  <InputLabel>Select Product</InputLabel>
                  <Select
                    label="Select Product"
                    name="package_id"
                    value={formData.package_id}
                    onChange={handleChange}
                    defaultValue=""
                    required
                  >
                    
                    <MenuItem value="Genmai Content Pro">Genmai Content Pro</MenuItem>
                    
                    
                  </Select>
                </FormControl>
                <TextField
                  label="Website Address"
                  variant="outlined"
                  fullWidth
                  name="domain_name"
                  value={formData.domain_name}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                  required
                  sx={{ width: '100%', height: '40px' }} // Adjust the height as needed
                  
                />
                {formData.package_id === "Genmai Content Pro" && (
                <TextField
                  label="Business Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                  required
                  helperText={
                    <span>
                      *Enter in as much detail as you like about your business, contact details, profession, products, services, competition, testimonials, call to action, area served, and unique aspects. Refer to{' '}
                      <a href="https://genmai.ai/genmai-resources/crafting-effective-business-description/" target="_blank" rel="noopener noreferrer">
                        this guide
                      </a>{' '}
                      for crafting an effective business description. * Optional for "GENmAI content"
                    </span>
                  }
                />
                )}
                <ReCAPTCHA
                  sitekey="6Leeeb0pAAAAAFP2KVQ-_wuo6XR25LoBladdjubE"
                  ref={captchaRef}
                  size="invisible"
                 // onChange={handleCaptchaChange} // Make sure to handle captcha change event
                />
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
                
                
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
     
    {/*  <AppBar position="static" color="primary" style={{ marginTop: 'auto' }}>
        <Toolbar>
          <Typography variant="body1" color="inherit">
            Copyright GENmAI 2024
          </Typography>
        </Toolbar>
              </AppBar> */}
    
    </div>
  );
};

export default RegisterFormN;
