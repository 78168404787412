import React, { useState } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
//import AccountInfoTable from './AccountInfoTable';
import AccountInfoTableN from './AccountInfoTableN';
//import SubscriptionInfoTable from './SubscriptionInfoTable';
import SubscriptionInfoTableN from './SubscriptionInfoTableN';
//import NewSubscriptionTable from './NewSubscriptionTable';
import NewSubscriptionTableN from './NewSubscriptionTableN';
import './UserInfo.css'; // Import CSS file for styling

const UserInfoN = React.memo(({ userInfo, handleUpdateSubscription, handleUpdateUser, handleCreateSubscription }) => {
//const UserInfoN = ({ userInfo, handleUpdateSubscription, handleUpdateUser, handleCreateSubscription }) => {
  // State for editable user information
  const [editableUser, setEditableUser] = useState({
    name: userInfo.name,
    email: userInfo.email,
    address: userInfo.address,
    phone: userInfo.phone
  });
    
  // Function to handle changes in user information fields
  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setEditableUser({
      ...editableUser,
      [name]: value
    });
  };

  // Function to save updated user information
  const saveUserChanges = () => {
    const updatedDataWithId = {
      accountid: userInfo.accountid,
      ...editableUser
    };
    handleUpdateUser(updatedDataWithId);
  };

  // State for editable subscription information
  const [editableSubscriptions, setEditableSubscriptions] = useState(userInfo.subscriptions);

  // Function to handle changes in subscription information fields
  const handleSubscriptionChange = (index, field, value) => {
    const updatedSubscriptions = [...editableSubscriptions];
    updatedSubscriptions[index][field] = value;
    setEditableSubscriptions(updatedSubscriptions);
  };

  // Function to save updated subscription information
  const saveSubscriptionChanges = (index) => {
    handleUpdateSubscription(editableSubscriptions[index].subscription_id, editableSubscriptions[index]);
  };

  // State for new subscription form
  const [newSubscription, setNewSubscription] = useState({
    associated_email: '',
    package_id: '',
    description: '',
    folder_link: '',
    domain_name: ''
  });

  // Function to handle changes in new subscription form fields
  const handleNewSubscriptionChange = (e) => {
    const { name, value } = e.target;
    setNewSubscription({
      ...newSubscription,
      [name]: value
    });
  };

  // Function to handle subscription creation
  const handleCreateNewSubscription = () => {
    handleCreateSubscription(newSubscription);
    // Clear form fields after submission
    setNewSubscription({
      associated_email: '',
      package_id: '',
      description: '',
      folder_link: '',
      domain_name: ''
    });
  };

  // State for controlling expanded sections
  const [expandedSections, setExpandedSections] = useState({
    accountInfo: true,
    subscriptionInfo: true,
    newSubscription: false
  });

  // Function to toggle expanded sections
  const handleToggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  return (
    <Container maxWidth="xl" style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '50px' }}>
      <Grid container spacing={2}>
        {/* First row */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <AccountInfoTableN
              userInfo={userInfo}
              editableUser={editableUser}
              handleUserChange={handleUserChange}
              saveUserChanges={saveUserChanges}
              expanded={expandedSections.accountInfo}
              onToggle={() => handleToggleSection('accountInfo')}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <NewSubscriptionTableN
              newSubscription={newSubscription}
              handleNewSubscriptionChange={handleNewSubscriptionChange}
              handleCreateNewSubscription={handleCreateNewSubscription}
              expanded={expandedSections.newSubscription}
              onToggle={() => handleToggleSection('newSubscription')}
            />
          </Paper>
        </Grid>
       
        {/* Second row */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <SubscriptionInfoTableN
              editableSubscriptions={editableSubscriptions}
              handleSubscriptionChange={handleSubscriptionChange}
              saveSubscriptionChanges={saveSubscriptionChanges}
              expanded={expandedSections.subscriptionInfo}
              onToggle={() => handleToggleSection('subscriptionInfo')}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );

},
 // (prevProps, nextProps) => {
  // Compare previous and current userInfo props
 // return prevProps.userInfo === nextProps.userInfo;
//}
);

export default UserInfoN;
